.App{
	background-color: aquamarine;
}
#root {
	font-family: "Unbounded", cursive;
	width: 100vw;
	overflow-x: hidden;
	--app-height: 100%;
	height: 100%;
height: -webkit-fill-available;
}
body {
	font-family: "Unbounded", cursive;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

nav.ui.container .ui.header {
	margin: 12px;
	font-family: "Unbounded", cursive;
}
.ui.inverted.vertical.segment {
	height: 100vh;
	background-color: rgb(51, 51, 51);
	padding-bottom: 60px;
}
.ui.column.grid>.column, .ui.grid>.column.row>[class*="eight wide"].column, .ui.grid>.row>[class*="eight wide"].column, .ui.grid>[class*="eight wide"].column{
	padding: 0;
}

.ui.grid>.row{
	padding: 0;
}
.ui.modal.temp {
	font-family: "Unbounded", cursive;
}

h1 {
	font-family: "Unbounded", cursive;
}
h2 {
	font-family: "Unbounded", cursive;
}
h3 {
	font-family: "Unbounded", cursive;
}

h4 {
	font-family: "Unbounded", cursive;
	margin-bottom: 0;
}
.temp-label{
	margin-top: 8px;
	margin-bottom: 0;
}
p {
	/* font-size: 2rem; */
	font-family: "Unbounded", cursive;
}
.temp {
	font-size: 3rem;
	padding: 0;
	margin: 0;
}
.title {
	font-size: 3rem;
	transition: hover 0.6s;
}
.tiny {
	font-size: 0.75rem;
	animation: pulse 6s infinite;
	color: aquamarine;
	transition: font-size 1s ease-in-out 100ms;
}
@keyframes pulse {
	0% {
		transform: scale(0.05);
		color: rgb(178, 248, 225);
	}

	70% {
		transform: scale(1.5);
		color: aquamarine;
	}

	100% {
		transform: scale(0.05);
		color: white;
	}
}
.tiny:hover {
	font-size: 3rem;
	text-decoration: underline;
}
.ui.teal.button.font, .ui.grey.button.font,
.ui.black.button.font {
	font-family: "Unbounded", cursive;
	font-size: 1.5rem;
	width: 100%;
}

.reading {
	margin-top: 0;
	font-family: "Unbounded", cursive;
	font-size: x-large;
}
.buttons {
	position: absolute;
	left: 0;
	bottom: 3rem;
	right: 0;
	font-family: "Unbounded", cursive;
}
.ui.card>.content>.header:not(.ui),
.ui.card>.content, .ui.cards>.card>.content{
	color: black;
	font-size: small;
	text-align: justify;
	font-family: 'Unbounded', cursive;
}
.reviews {
	padding: 10px;
	height: 275px;
	overflow-y: auto;
	overflow-x: hidden;
}
.ui.modal>.header:not(.ui){
	font-family: 'Unbounded', cursive;
}
.sign-in {
    display: flex;
    justify-content: flex-end;
	margin-bottom: 8px;
}
.user-buttons {
	display: flex;
	flex-direction: column;
}
.ui.huge.button, .ui.huge.buttons .button, .ui.huge.buttons .or {
	font-family: 'Unbounded', cursive;

	margin: 1rem;
	align-items: center;
	text-align: center;
}
.sign-in-form{
	display: flex;
	justify-content: center;
}
.sign {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.ui.form .field>label{
	display: flex;
	color: white;
    max-width: 100%;
    flex-direction: column;
    width: 200px;
	justify-content: center;
	align-items: center;
}
.warning {
	width: auto;
	text-align:center;
}
.review-box{
	touch-action: manipulation;
}
.user-posts {
	padding: 10px;
	height: 75vh;
	overflow-y: auto;
	overflow-x: hidden;
}