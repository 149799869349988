.alert {
  align-items: center;
  animation: .5s ease-in-out 0s 1 light-bounce-in;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 15px;
  max-width: 100vw;
  width: 100vw;
  top: 100px;
  z-index: 10000;

  // .alert-body {
  //   margin-bottom: 0;
  // }
}

.close {
  &:active,
  &:focus {
    outline: none;
  }
}

@keyframes light-bounce-in {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }

  50% {
    transform: translateY(-5%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
